<template>
  <div class="terms-wrapper">
    <div class="terms-title">Условия использования</div>
    <p>
      <b>Условия использования сайта "Школа Библии"</b><br />
      Внимание! Перед просмотром Сайта внимательно прочтите эти Условия. Если вы не
      согласны с настоящими Условиями, не используйте Сайт.
    </p>

    <p>
      <b>Использование Сайта</b><br />
      Сайт позволяет вам просматривать и загружать материалы с него (далее «Сайт») только
      для личного некоммерческого использования, при сохранении вами всей информации об
      авторском праве и других сведений о праве собственности, содержащихся в исходных
      материалах и любых их копиях. Запрещено изменять материалы этого Сайта, а также
      распространять или демонстрировать их в любом виде или использовать их каким-либо
      другим образом для коммерческих целей.
    </p>

    <p>
      <b>Отказ от ответственности</b><br />
      Материалы и услуги этого сайта предоставлены без каких-либо гарантий и
      ответственности за них со стороны владельцев и администрации Сайта. Сайт не
      гарантирует точность и полноту материалов, программ и услуг, предоставляемых на этом
      Сайте. В любое время без уведомления могут вноситься изменения в материалы и услуги,
      предоставляемые на данном Сайте. Владельцы и администрация Сайта ни при каких
      обстоятельствах не несут ответственности за любые убытки, возникшие в результате
      использования, невозможности использования или результатов использования этого
      сайта.
    </p>

    <p>
     <b> Регистрация на сайте </b> <br />
      Регистрируясь на Сайте, вы соглашаетесь предоставить достоверную информацию о себе и
      своих контактных данных.
      Обратная связь и комментарии<br />
      Обращаясь к нам, вы несете ответственность, что это сообщение не является
      незаконным, вредным, угрожающим, клеветническим, противоречит моральным нормам,
      нарушает авторские права, пропагандирует ненависть и/или дискриминацию людей по
      расовому, этническому, половому, религиозному, социальному признакам, содержит
      образы в адрес конкретных лиц или организаций, а также каким-либо иным образом
      нарушает действующее законодательство Украины. Вы соглашаетесь, что любое ваше
      сообщение администрация Сайта может удалять без вашего согласия, а также
      безвозмездно использовать по своему усмотрению. Владельцы и администрация сайта не
      несут ответственности за любую информацию, размещенную пользователями сайта.
    </p>

    <p>
      <b>Использование персональных данных</b><br />
      Мы используем различные технологии для сбора и хранения информации, когда вы
      посещаете Сайт. Это может включать в себя запись одного или нескольких куков или
      анонимных идентификаторов.
    </p>
  </div>
</template>
<style scoped>
.terms-wrapper {
  max-width: 1218px;
  padding: 60px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.terms-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.terms-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}
.terms-wrapper b {
  font-weight: 600;
}
</style>
